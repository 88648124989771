<template>
  <div class="GlobalFormDemo">
    <GlobalForm
      :init-data="initData"
      :form-rules="formRules"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="85"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        class="el_from"
        label="订单状态"
        slot="state"
        style="margin:0"
      >
        <el-select
          v-model="state"
          placeholder="请选择订单状态"
          clearable
          multiple
        >
          <el-option
            v-for="item in stateOptions"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>

<!--      <el-form-item-->
<!--        class="el_from select_down"-->
<!--        label="询价门店"-->
<!--        slot="storeId"-->
<!--        style="margin:0"-->
<!--      >-->
<!--        <el-select-->
<!--          filterable-->
<!--          :popper-append-to-body="false"-->
<!--          v-model="storeId"-->
<!--          placeholder="请选择询价门店"-->
<!--          clearable-->
<!--        >-->
<!--          <el-option-->
<!--            v-for="item in storeList"-->
<!--            :key="item.key"-->
<!--            :label="item.value"-->
<!--            :value="item.key"-->
<!--          ></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
<!--      <el-form-item-->
<!--        class="el_from select_down"-->
<!--        label="禁用门店"-->
<!--        slot="unEnableStoreId"-->
<!--        style="margin:0"-->
<!--      >-->
<!--        <el-select-->
<!--          filterable-->
<!--          :popper-append-to-body="false"-->
<!--          v-model="unEnableStoreId"-->
<!--          placeholder="请选择已禁用门店"-->
<!--          clearable-->
<!--        >-->
<!--          <el-option-->
<!--            v-for="item in storeUnEnableList"-->
<!--            :key="item.key"-->
<!--            :label="item.value"-->
<!--            :value="item.key"-->
<!--          ></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->

<!--      <el-form-item-->
<!--        class="el_from select_down"-->
<!--        label="回收商家"-->
<!--        slot="merchantId"-->
<!--        style="margin:0"-->
<!--      >-->
<!--        <el-select-->
<!--          :popper-append-to-body="false"-->
<!--          v-model="merchantId"-->
<!--          placeholder="请选择询价商家"-->
<!--          clearable-->
<!--        >-->
<!--          <el-option-->
<!--            v-for="item in merchantList"-->
<!--            :key="item.merchantId"-->
<!--            :label="item.merchantName"-->
<!--            :value="item.merchantId"-->
<!--          ></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item label="询价商家" slot="companyId" style="margin:0">
        <el-select
            multiple
            collapse-tags
            v-model="companyIds"
            @change="handleChangeInquiry"
            @remove-tag="removeTag"
            placeholder="请选择"
            filterable
            clearable
            reserve-keyword
        >
          <el-option
              v-for="item in InquirySelectList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="询价门店" slot="storeId" style="margin:0">
        <el-select
            v-model="storeId"
            placeholder="请先选择门店商家"
            :disabled="isHasCompany"
            filterable
            clearable
            @change="handleChangeStore"
        >
          <el-option
              v-for="item in storeList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from select_down"
        label="回收分类"
        slot="typeId"
        style="margin:0"
      >
        <el-select
          :popper-append-to-body="false"
          v-model="typeId"
          placeholder="请选择回收分类"
          clearable
        >
          <el-option
            v-for="item in machineType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        :label-width="'100px'"
        label="是否线上支付"
        slot="operation"
        style="margin:0"
      >
        <el-select v-model="isEnablePay" placeholder="请选择订单状态">
          <el-option
            v-for="item in optionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        :label-width="'120px'"
        label="是否付款给用户"
        slot="operation"
        style="margin:0"
      >
        <el-select v-model="userIsCollect" placeholder="请选择订单状态">
          <el-option
            v-for="item in optionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        class="el_from"
        :label-width="'120px'"
        label="是否加价给用户"
        slot="isUsedAddPrice"
        style="margin:0"
      >
        <el-select v-model="isUsedAdd" placeholder="请选择订单状态">
          <el-option
            v-for="item in isUsedAddPrice"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

<!--      <el-form-item-->
<!--        v-if="staffType == '01'"-->
<!--        class="el_from"-->
<!--        label="所属区域"-->
<!--        slot="areaId"-->
<!--        style="margin:0"-->
<!--      >-->
<!--        <el-select clearable v-model="areaIddata" placeholder="请选择所属区域">-->
<!--          <el-option-->
<!--            v-for="item in areagetAreaSelect"-->
<!--            :key="item.staffId"-->
<!--            :label="-->
<!--              item.areaName-->
<!--                ? `${item.staffName}-${item.areaName}`-->
<!--                : item.staffName-->
<!--            "-->
<!--            :value="item.staffId"-->
<!--          >-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->

      <el-form-item
        class="el_from"
        label="下单时间"
        slot="entryTime1"
        style="margin:0"
      >
        <el-date-picker
          v-model="entryTime"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          @change="dateChange"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="成交时间"
        slot="entryTime"
        style="margin:0"
      >
        <el-date-picker
          v-model="ackTime"
          type="daterange"
          start-placeholder="开始日期"
          range-separator="~"
          end-placeholder="结束日期"
          clearable
          @change="dateChange"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      <div slot="entryTime3" class="flex">
        <el-select v-model="otherDateType" class="time-type" style="width:125px;border:none">
          <el-option
            v-for="ele in timeTypeOption"
            :key="ele.key"
            :label="ele.label"
            :value="ele.key">
          </el-option>
        </el-select>
        <el-date-picker
          v-model='entryTime3'
          type="daterange"
          start-placeholder="开始日期"
					range-separator="~"
          end-placeholder="结束日期"
					clearable
          @change="dateChange2"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </div>
      <el-form-item
        v-if="isOut"
        class="el_from"
        label-width="110px"
        label="外部订单号"
        slot="orderNoOneline"
        style="margin:0"
      >
        <el-input
          v-model="orderNoOneline"
          placeholder="请输入外部订单号"
          clearable
        />
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import { formValidateMobile } from "@/utils/form-validate";
import moment from "moment";
import _api from "@/utils/request";
export default {
  name: "Form",
  data() {
    let _this = this;
    return {
      isOut: false,
      areagetAreaSelect: [],
      areaIddata: "",
      isUsedAdd: "",
      storeId: "",
      isHasCompany:true,
      companyId:"",
      companyIds: [], //修改询价商家为多选
      InquirySelectList: [],
      unEnableStoreId: "",
      merchantId: "",
      typeId: "",
      orderNoOneline: "",
      state: [],
      isEnablePay: "",
      userIsCollect: "",
      stateOptions: [],
      storeList: [],
      storeUnEnableList: [],
      machineType: [],
      merchantList: [],
      entryTime: null,
      ackTime: null,
      entryTime3: null,
      initData: null,
      startTime: "",
      endTime: "",
      optionList: [
        { value: "", label: "全部" },
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      formItemList: [
        {
          key: "orderNo",
          type: "input",
          labelName: "订单编号",
          placeholder: "请输入订单编号",
        },
        {
          key: "orderUnique",
          type: "input",
          labelName: "订单唯一码",
          placeholder: "请输入订单唯一码",
        },
        {
          key: "imei",
          type: "input",
          labelName: "串号",
          placeholder: "请输入旧机串号",
        },
        {
          slotName: "state",
        },
        {
          key: "phoneName",
          type: "input",
          labelName: "回收商品",
          placeholder: "请输入商品名称",
        },
        { slotName: 'companyId' },
        {
          slotName: "storeId",
        },
        {
          slotName: "unEnableStoreId",
        },
        {
          key: "staffName",
          type: "input",
          labelName: "下单店员",
          placeholder: "请输入下单店员姓名",
        },
        {
          slotName: "merchantId",
        },
        {
          slotName: "typeId",
        },
        /*********************************************** 更改位置 */
        {
          key: "recycleWay",
          type: "select",
          labelName: "回收方式",
          width: 85,
          placeholder: "请选择",
          option: [
            {
              value: "01",
              label: "以旧换新",
            },
            {
              value: "02",
              label: "仅回收",
            },
          ],
        },
        { slotName: "areaId" },
        {
          key: "isBindBarCode",
          type: "select",
          labelName: "是否绑码",
          width: 85,
          placeholder: "请选择",
          option: [
            {
              value: "01",
              label: "已绑码",
            },
            {
              value: "02",
              label: "未绑码",
            },
          ],
        },
        { slotName: "entryTime1" },
        { slotName: "entryTime" },
        { slotName: "entryTime3" },
        {
          key: "isNiceBiz",
          type: "select",
          lableWidth: "125",
          labelName: "是否为靓机订单",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },

        { slotName: "operation" },
        { slotName: "isUsedAddPrice" },
        {
          key: "isReBuild",
          type: "select",
          lableWidth: "125",
          labelName: "是否为重评订单",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "isInside",
          type: "select",
          lableWidth: "125",
          labelName: "内部加价成交",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: true,
              label: "是",
            },
            {
              value: false,
              label: "否",
            },
          ],
        },
        {
          key: "niceAckPayment",
          type: "select",
          lableWidth: "110",
          labelName: "订单付款方",
          option: [
            {
              value: "",
              label: "全部",
            },
            {
              value: "01",
              label: "成交商家付款",
            },
            {
              value: "02",
              label: "靓机商家付款",
            },
          ],
        },
        { slotName: "orderNoOneline" },
        {
          key: 'expressNo',
          type: 'input',
          labelName: '运单查询',
          placeholder: '请输入运单号',
        },
        {
          key: 'isReimg',
          type: 'select',
          lableWidth:'105px',
          labelName: '是否发起补拍',
          option: [
            {
              value: '',
              label: '全部',
            },
            {
              value: true,
              label: '是',
            },
            {
              value: false,
              label: '否',
            },

          ],
        },
        // 旧机情况
        {
          key: 'healthCode',
          type: 'select',
          lableWidth:'80px',
          labelName: '旧机情况',
          option: [],
        }
        /*********************************************** 更改位置 */
      ],
      isUsedAddPrice: [
        { value: "", label: "全部" },
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      formRules: {
        mobile: [
          {
            validator: formValidateMobile,
            trigger: "blur",
          },
        ],
      },
      SeachParams: {},
      otherDateType: '01',
      timeTypeOption: [
        {
          key: '01',
          label: '收货时间'
        },
        {
          key: '02',
          label: '退款时间'
        },
        {
          key: '03',
          label: '作废时间'
        },
        {
          key: '04',
          label: '取消时间'
        }
      ],
    };
  },
  created() {
    let staffType = JSON.parse(localStorage.getItem("user"))??{};
    this.staffType = staffType.staffType;
    let isOut = JSON.parse(localStorage.getItem("user"))??{};
    this.isOut = isOut.isOut;
    // 修改询价商家为多选
    if (this.$route.query.companyId) {
      this.companyIds = [Number(this.$route.query.companyId)] || [];
      this.getStoreSelectList(); //新加
    }
    _api.getSelectList().then((res) => {
      if (res.code === 1) {
        this.InquirySelectList = res.data
      }
    })
    // _api.getNotPInspectorSelect({ scope: "00" }).then((res) => {
    //   this.areagetAreaSelect = res.data;
    // });

    let startTime = moment()
      .day(moment().day() - 14)
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推一周的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.entryTime = [startTime, endTime];
    _api.orderStateList().then((res) => {
      console.log(res);
      this.stateOptions = res.data;
    });
    this.getStoreList();
    // this.getStoreSelectUnEnable();
    // this.getmerchantList();
    this.getAllMachineType();
  },
  watch: {
    unEnableStoreId(val) {
      if (val && this.storeId) {
        this.storeId = "";
      }
    },
    storeId(val) {
      if (val && this.unEnableStoreId) {
        this.unEnableStoreId = "";
      }
    },
    // companyId(newVal){
    //   console.log(newVal);
    //   if(newVal != ''){
    //     this.isHasCompany = false
    //   }else{
    //     this.storeId = ''
    //     this.isHasCompany = true
    //   }
    // },
    // 修改：询价商家选了一个可以选询价门店，否则多选/不选不可以选询价门店
    companyIds(newVal) {
      console.log(newVal, "询价商家变了");
      if (newVal.length !== 1) {
        this.storeId = "";
        this.isHasCompany = true;
      } else {
        this.isHasCompany = false;
      }
    },
  },
  methods: {
    dateChange2(e){
      console.log(this.entryTime);
    },
    getStoreList() {
      // _api.getStoreSelectOrder().then((res) => {
      //   this.storeList = res.data;
      // });
      // 机况
      _api.getHealthList().then(r=>{
        const index = this.formItemList.findIndex(v=>v.key === 'healthCode');
        if(index>=0){
          this.formItemList[index].option = r.data.map(v=>{
            return {
              label:v.key,
              value:v.value
            }
          })
        }
      })
    },
    // getStoreSelectUnEnable() {
    //   _api.getStoreSelectUnEnable().then((res) => {
    //     this.storeUnEnableList = res.data;
    //   });
    // },
    // getmerchantList() {
    //   _api.getAllMiddleAdoptMerchant().then((res) => {
    //     this.merchantList = res.data;
    //   });
    // },
    getAllMachineType() {
      _api.getAllMachineType().then((res) => {
        this.machineType = res.data;
      });
    },
    dateChange(e) {
      console.log(this.entryTime);
    },
    handleChangeInquiry(val) {
      console.log(val);
      if (!val || !val.length) {
        this.companyIds = [];
        return;
      }
      if (
        !val.includes("全部选择") &&
        val.length === this.InquirySelectList.length
      ) {
        this.companyIds.unshift("全部选择");
      } else if (
        val.includes("全部选择") &&
        val.length - 1 < this.InquirySelectList.length
      ) {
        this.companyIds = this.companyIds.filter((item) => {
          return item !== "全部选择";
        });
      }
      // 获取询价门店
      console.log(this.companyIds, "000000000000000");
      if (this.companyIds.length === 1) {
        this.storeId = "";
        this.getStoreSelectList();
      }
      console.log(this.companyIds, "询价商家222222");
    },
    removeTag(val) {
      if (val === "全部选择") {
        this.companyIds = [];
      }
    },
    // 获取门店列表
    getStoreSelectList(){
      _api.getStoreSelectList({companyId: this.companyIds[0]}).then(res => {
        console.log(res);
        if(res.code === 1){
          this.storeList = res.data
        }
      })
    },
    searUnPayUserOrders() {
      this.state = ["01"];
      this.userIsCollect = false;
      this.isEnablePay = true;
      this.SeachParams.userIsCollect = false;
      this.SeachParams.isEnablePay = true;
      this.handleConfirm(this.SeachParams);
    },
    handleChangeStore(val){
      console.log(val)
      this.storeId = val
    },
    //查询
    handleConfirm(data,cd) {
      console.log("提交了form", data);
      console.log(this.entryTime);
      if (this.entryTime === null) {
        (this.startTime = ""), (this.endTime = "");
      } else {
        (this.startTime = moment(this.entryTime[0]).format("x")),
          (this.endTime = moment(this.entryTime[1]).format("x"));
      }
      if (this.ackTime === null) {
        (this.ackStartTime = ""), (this.ackEndTime = "");
      } else {
        (this.ackStartTime = moment(this.ackTime[0]).format("x")),
          (this.ackEndTime = moment(this.ackTime[1]).format("x"));
      }

      if (this.entryTime3 === null) {
        (this.otherStartTime = ''), (this.otherEndTime = '')
      } else {
        (this.otherStartTime = moment(this.entryTime3[0]).format("x")),
          (this.otherEndTime = moment(this.entryTime3[1]).format("x"))
      }
      console.log(this.startTime);
      console.log(this.endTime);
      let companyIdList = this.companyIds.filter((item) => {
        return item !== "全部选择";
      });
      const baseRequest = {
        businessType: data.businessType,
        typeId: this.typeId,
        imei: data.imei,
        isBindBarCode: data.isBindBarCode,
        niceAckPayment: data.niceAckPayment,
        recycleWay: data.recycleWay,
        orderNo: data.orderNo,
        orderUnique:data.orderUnique,
        expressNo: data.expressNo,
        phoneName: data.phoneName,
        state: this.state,
        // storeId: this.storeId || this.unEnableStoreId,
        storeId: this.storeId,
        merchantId: this.merchantId,
        isNiceBiz: data.isNiceBiz
          ? true
          : data.isNiceBiz === false
          ? false
          : "",
        isEnablePay: this.isEnablePay
          ? true
          : this.isEnablePay === false
          ? false
          : "",
        userIsCollect: this.userIsCollect
          ? true
          : this.userIsCollect === false
          ? false
          : "",
        isReBuild: data.isReBuild
          ? true
          : data.isReBuild === false
          ? false
          : "",
        isInside: data.isInside ? true : data.isInside === false ? false : "",
        isReimg: data.isReimg ? true : data.isReimg === false ? false : "",
        endTime: this.endTime,
        startTime: this.startTime,
        ackStartTime: this.ackStartTime,
        ackEndTime: this.ackEndTime,
        otherStartTime: this.otherStartTime,
        otherEndTime: this.otherEndTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        staffName: data.staffName,
        isUsedAddPrice: this.isUsedAdd,
        orderNoOneline: this.orderNoOneline,
        inspectorId: this.areaIddata,
        otherDateType: this.otherDateType,
        healthCode:data.healthCode||undefined,
        companyIdList: companyIdList,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      console.log(baseRequest);
      this.SeachParams = baseRequest;
      this.$store.commit("tagsView/SEACH_PARAMS", baseRequest);
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  /deep/.el-select-dropdown {
    border-radius: 20px;
  }
  .select_down {
    /deep/.el-popper {
      border-radius: 20px;
    }
    /deep/.el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: #e4ecfd;
      color: rgba(9, 129, 255, 1);
    }
  }
  .flex{
    display: flex;
    align-items: center;
  }
  .time-type{
    /deep/.el-input__inner{
      border: none;
    }
  }
}
</style>

<template>
  <div class="OrderList">
    <GlobalInfoBar title="回收订单" content="管理门店的报价订单，订单数据仅支持查看，不可操作" />
    <GlobalChunk icon="search" title="筛选查询">
      <!-- <div slot="tip" style="margin-left:20px;background-color: #e4ecfd;padding:5px 10px;border-radius: 20px;">共有<span style="color:#ff687b;cursor: pointer; text-decoration: underline;" @click="searchUnPayUserOrders">{{UnPayUserOrders}}单</span>未付款给用户</div> -->
      <div slot="filter">
        <!-- <OrderFrom :classList="classList" v-if="classList"/> -->
        <OrderFrom ref="childForm"/>
      </div>
      <div class="caseOrder">
        <OrderTable @getUnPayUserOrders="getUnPayUserOrders"/>
      </div>
    </GlobalChunk>
  </div>
</template>

<script>
import _api from "@/utils/request";
import OrderFrom from "./From"
import OrderTable from "./table"
export default {
  name: "OrderList",
  components:{OrderFrom,OrderTable},
  data() {
    return {
      classList: null,
      merchantId: '',
      UnPayUserOrders: 0
    };
  },
  created() {

    // this.getClassList()
    // this.getResidentList()
  },
  methods: {
    // 获取分类集合
    // getClassList() {
    //   _api.selectList().then(res => {
    //     console.log(res)
    //     this.classList = res.data?res.data:[]
    //   })
    // },
    getUnPayUserOrders(e) {
      this.UnPayUserOrders = e
    },
    searchUnPayUserOrders(){
      this.$refs.childForm.searUnPayUserOrders()
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.OrderList {
  .case {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .case-title {
      display: flex;
      .case-title_icon {
        width: 20px;
        height: 20px;
      }
      .case-title_max {
        font-weight: bold;
        color: #333333;
        font-size: 18px;
        width: 80px;
        height: 17px;
        line-height: 17px;
        font-family: FZLanTingHei-B-GBK;
      }
    }
  }
  .caseOrder {
    margin: 0 5px;
    background: #f5f6fa;
    border-radius: 10px;
  }
}
</style>
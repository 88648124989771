// 回收订单串号验机报告弹窗
export const imeiTestProps = {
    title: "旧机功能情况",
    show:false,
    width:'49%',
    isLoadding:false,
    phoneName:"",//另一个机型name
    // 02代表渲染功能情况
    type:"",
    // 检测项
    tableData:[
        {
            one:"1",
            two:"2",
            three:"3"
        },
        {
            one:"1",
            two:"2",
            three:"3"
        }
    ],
    // 功能情况
    functionList:[],
    sku: { skus: [] },//外部规格
    // 系统检测结果
    systemTestResult:[],
    // 是否无法检测
    unableDetectionCause:"",
    // 是否系统检测且存在检测结果
    isSystem:false,
    // 其他功能标题显示，原需求模糊，抄详情写
    isOtherTitileShow:false,
    // 其他功能表格 原需求模糊，抄详情写
    isOtherTableShow:false,
    // 是否显示成色
    isFinenessShow:false,
    // 成色数据
    finenessData:[],
    // 旧机照片
    photoArr:[],
    // 保存值，防止被删除
    allSystemTestList:[
        {
            value: "",
            key: "mobileNetworks",
            lable: "1.移动数据",
            isSystem:true
        },
        {
            value: "",
            key: "wlan",
            lable: "2.WI-FI",
            isSystem:true
        },
        {
            value: "",
            key: "bluetooth",
            lable: "3.蓝牙",
            isSystem:true
        },
        {
            value: "",
            key: "camera",
            lable: "4.摄像头",
            tipKey:"cameraCause",
            tips:""
        },
        {
            value: "",
            key: "fingerprintOrFace",
            lable: "5.指纹/面容识别",
        },
        {
            value: "",
            key: "screenTouch",
            lable: "6.触屏功能",
        },
        {
            value: "",
            key: "screenDisplay",
            lable: "7.屏幕显示",
            tipKey:"screenDisplayCause",
            tips:""
        },
        {
            value: "",
            key: "conversation",
            lable: "8.拨打电话"
        },
        {
            value: "",
            key: "charge",
            lable: "9.充电功能"
        }
    ],
    // 系统验机项
    systemTestList:[],
    orderMode:"",//下单模式
    funcDetail:{}//
}
